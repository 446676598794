const $ = jQuery;

export default class StickyNav {
    constructor(options = null) {
        this.$body = $("body");
        this.$navbar = $("header");
        this.$menuItem = $("#menu-primary>.menu-item");
    }

    init() {
        this.__listen();
    }

    __listen() {
        this.__stickFunction();
        this.__openMenu();
    }

    __openMenu() {
        this.$body.on(
            "click",
            "#menu-primary>.menu-item.menu-item-has-children.opened",
            (event) => {
                const target = event.target;
                if ($(target).hasClass("menu-item-has-children")) {
                    this.$menuItem.removeClass("opened");
                    $("header").removeClass("header__openMenu");
                    $("#menu-primary>.menu-item").removeClass("opened");
                }
            }
        );

        this.$body.on(
            "click",
            "#menu-primary>.menu-item.menu-item-has-children:not(.opened)",
            (event) => {
                const currenttarget = event.currentTarget;

                if (!$(this).hasClass("opened")) {
                    // event.preventDefault();
                }
                this.$menuItem.removeClass("opened");

                currenttarget.classList.add("opened");
                // event.preventDefault();
                this.$navbar.addClass("header__openMenu").focus();
            }
        );

        $(document).click(function (event) {
            if (
                !$(event.target).closest(
                    "#menu-primary>.menu-item.menu-item-has-children"
                ).length
            ) {
                // Code to execute when a click happens outside of the element
                $("header").removeClass("header__openMenu");
                $("#menu-primary>.menu-item").removeClass("opened");
            }
        });
    }

    __stickFunction() {
        const navbar = this.$navbar[0];
        const sticky = navbar.offsetTop + 1;

        window.onscroll = function () {
            if (window.pageYOffset > sticky) {
                navbar.classList.add("header__stickyNav");
            } else {
                navbar.classList.remove("header__stickyNav");
            }
        };
    }
}
